import BaseViewBackground from './BaseViewBackground'
import BaseViewBody from './BaseViewBody'
import BaseViewContent from './BaseViewContent'
import BaseViewFooter from './BaseViewFooter'
import BaseViewHead from './BaseViewHead'
import BaseViewLogo from './BaseViewLogo'
import BaseViewRoot from './BaseViewRoot'
import BaseViewStepper from './BaseViewStepper'

export default {
  Root: BaseViewRoot,
  Logo: BaseViewLogo,
  Background: BaseViewBackground,
  Head: BaseViewHead,
  Stepper: BaseViewStepper,
  Body: BaseViewBody,
  Content: BaseViewContent,
  Footer: BaseViewFooter,
}
