import type { LogoProps } from '@lemonenergy/lemonpie-3'
import { cn, Logo } from '@lemonenergy/lemonpie-3'

const BaseViewLogo = ({
  variant,
  className,
  ...props
}: Omit<LogoProps, 'variant'> & {
  variant?: LogoProps['variant']
}) => {
  return (
    <Logo
      data-testid="logo-lemon"
      variant={variant ?? 'symbol'}
      className={cn(
        'h-large w-auto group-data-[variant=expressive]/baseview:fg-inverted-main group-data-[variant=primary]/baseview:fg-expressive-main',
        className,
      )}
      {...props}
    />
  )
}

export default BaseViewLogo
