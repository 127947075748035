import { cn } from '@lemonenergy/lemonpie-3'
import { Slot } from '@radix-ui/react-slot'
import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

import type { AttributesSlottable } from '~/utils/type.utils'

const BaseViewContent = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & AttributesSlottable<{}>
>(({ children, className, asChild, ...props }, forwardedRef) => {
  const Component = asChild ? Slot : 'div'
  return (
    <Component
      className={cn('pt-larger', className)}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </Component>
  )
})

BaseViewContent.displayName = 'BaseViewContent'

export default BaseViewContent
