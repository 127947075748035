import { cn } from '@lemonenergy/lemonpie-3'
import { Slot } from '@radix-ui/react-slot'
import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

import type { AttributesSlottable } from '~/utils/type.utils'

const BaseViewRoot = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> &
    AttributesSlottable<{
      variant?: 'expressive' | 'primary'
    }>
>(
  (
    { children, className, variant = 'primary', asChild, ...props },
    forwardedRef,
  ) => {
    const Component = asChild ? Slot : 'main'
    const background =
      variant === 'primary' ? 'bg-white' : 'bg-expressive-strong'
    return (
      <Component
        className={cn(
          'content-grid grid-rows-[fit-content(100%)_1fr] sm:items-start group/baseview min-h-full flex-1',
          background,
          className,
        )}
        {...props}
        data-variant={variant}
        ref={forwardedRef}
      >
        {children}
      </Component>
    )
  },
)

BaseViewRoot.displayName = 'BaseViewRoot'

export default BaseViewRoot
